import React, { FunctionComponent } from 'react';

import { Button, TextField, Typography } from '@mui/material';
import TextEditor from '../.././../../view/component/text-editor/TextEditor';
import LoadingBackdrop from '../../backdrop/LoadingBackdrop';

interface Props {
  title: string;
  onChangeTitle: (value: string) => void;
  contents: string;
  onChangeContents: (value: string) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onClickRegister: () => void;
  isLoading: boolean;
}

const NoticesRegisterPage: FunctionComponent<Props> = ({
  title, onChangeTitle,
  contents, onChangeContents,
  fileInputRef,
  onClickRegister,
  isLoading,
}) => {
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        공지사항
      </Typography>

      <TextField
        label="제목"
        fullWidth
        variant="outlined"
        margin="normal"
        value={title}
        onChange={(event) => onChangeTitle(event.target.value)}
        disabled={isLoading}
      />

      <div style={{ marginBottom: '20px' }}>
        <TextEditor
          value={contents}
          onChange={onChangeContents}
          placeholder="내용을 입력해주세요"
          readonly={isLoading}
        />
      </div>

      <input
        type="file"
        multiple
        style={{ marginBottom: '20px', display: 'block' }}
        ref={fileInputRef}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={onClickRegister}
        disabled={isLoading}
      >
        등록하기
      </Button>
      <LoadingBackdrop
        isShown={isLoading}
      />
    </div>
  );
};

export default NoticesRegisterPage;
