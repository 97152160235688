import { CallabtSDK } from 'callabt-sdk';
import React, { FunctionComponent, useRef, useState } from 'react';
import PageWrapper from '../../../lib/PageWrapper';
import NoticesRegisterPage from '../../../view/component/page/NoticesRegisterPage';
import { navigate } from 'gatsby';
import { getNoticesPagePath } from '../../../lib/app-path';

const NoticesRegister: FunctionComponent = () => {
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClickRegister = async () => {
    if (title.length === 0) {
      alert('제목을 입력해 주세요.');
      return;
    }
    if (contents.length === 0) {
      alert('내용을 입력해 주세요.');
      return;
    }

    const fileList: File[] = [];

    if (fileInputRef.current && fileInputRef.current.files) {
      for(let i = 0; i < fileInputRef.current.files.length; i += 1) {
        fileList.push(fileInputRef.current.files[i]);
      }
    }

    setIsLoading(true);
    try {
      await CallabtSDK.createNotice({
        title,
        contents,
        files: fileList,
      });
      alert('등록되었습니다.');
      navigate(getNoticesPagePath());
    } catch (error) {
      console.error(error);
      alert('등록에 실패했습니다.');
    }
    setIsLoading(false);
  };

  return (
    <PageWrapper>
      <NoticesRegisterPage
        title={title}
        onChangeTitle={setTitle}
        contents={contents}
        onChangeContents={setContents}
        fileInputRef={fileInputRef}
        onClickRegister={onClickRegister}
        isLoading={isLoading}
      />
    </PageWrapper>
  );
};

export default NoticesRegister;
